import React from 'react'
import picFaceNoBg from '../assets/picFaceNoBg.png'

const Hero = () => {
  return (
    <div name='home' className='flex flex-col justify-between w-full h-screen bg-gray-900 text-white text-center'>
        <div className='grid md:grid-cols-2 max-w-screen-xl m-auto px-3'>
            <div className='flex flex-col justify-center md:items-center w-full px-2 py-8'>
                <p className='text-2xl text-gray-500'>Quer Viver so de Mercado Financeiro?</p>
                <h1 className='pt-6 pb-6 text-5xl md:text-7xl md:text-center font-bold text-lightColor'>Wellington Melo</h1>
                <span className='pt-1 pb-6 text-5xl md:text-7xl md:items-center font-bold'>Trader</span>
                <h1 className='pt-6 pb-6 text-5xl md:text-5xl md:text-center font-bold text-lightColor'>Em Breve Novo Site</h1>
            </div>
            <div className='flex items-center justify-center'>
                <img src={picFaceNoBg} alt='wmFullPic' className='rounded-full w-3/4' />
            </div>
        </div>
    </div>
  )
}

export default Hero