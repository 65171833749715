import React from 'react'

const ButtonEbook = () => {
  return (
    <div className='w-full mx-auto text-md md:text-lg'>
        <h1 className='bg-[#f6a604] rounded-xl uppercase p-2 text-center'>clique aqui para receber o ebook de graça</h1>
    </div>
  )
}

export default ButtonEbook