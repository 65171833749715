import React from 'react'
import { BiTrendingDown } from 'react-icons/bi'
import ReactPlayer from 'react-player'
import wmEbook from '../assets/ebookVideo.mp4'
import ButtonEbook from '../components/ButtonEbook'
import questionMark from '../assets/animated-question-mark.png'


const Ebook = () => {
  return (
    <div className='flex items-center flex-col'>
        <div className='w-full h-screen bg-darkColor flex flex-col items-center'>
            <div className='w-full lg:w-[80%] bg-[#1fe4e4] text-darkColor py-2 text-2xl flex justify-center'>
                <h1>EBOOK DO TRADER PROFISSIONAL</h1>
            </div>
            <div className='w-full lg:w-[80%] text-white py-2 text-2xl flex justify-center'>
                <h1>ESTRATÉGIAS PARA</h1>
            </div>
            <div className='w-full lg:w-[80%] bg-[#1fe4e4] text-darkColor py-2 text-2xl flex justify-center shadow-lg shadow-[#1fe4e4]'>
                <h1>DAY-TRADE E SWING-TRADE</h1>
            </div>
            <div className='w-full lg:w-[80%] text-white pt-8 text-2xl flex justify-center text-center'>
                <h1>ASSISTA VIDEO AO LODO PARA SABER COMO ADQUIRIR O EBOOK GRATUITO</h1>
            </div>
            <div className='text-white flex justify-center md:pt-2 md:pb-6'>
                <BiTrendingDown size={70} />
            </div>
            <div className='w-[100%] px-2 flex justify-center'>
                <ReactPlayer url={ wmEbook } controls={true} />
            </div>
            <div className='py-4 mx-auto text-2xl'>
                <h1 className='bg-white px-2'>NUNCA MAIS VOCÊ VAI TRAVAR</h1>
            </div>
            <div className='text-white pb-2 text-2xl flex justify-center'>
                <h1>NA HORA DE ENTRAR EM UMA OPERAÇÃO</h1>
            </div>
            <div>
             <ButtonEbook />
            </div>
        </div>
        <div className='w-full h-screen bg-darkColor flex flex-col items-center'>
            <div className='flex justify-center items-center mt-8 w-full lg:w-[80%]'>
                <div className='flex w-[100%] justify-end'>
                    <img src={questionMark} alt='/' className='md:w-[50%]' />
                </div>
                <div className='w-[100%]'>
                    <p className='text-white text-4xl font-bold pb-4'>VOCÊ É</p>
                    <p className='text-[#1fe4e4] text-2xl font-bold pb-4'>OU GOSTARIA DE SER TRADER ?</p>
                    <p className='text-white text-center text-xl font bold'>E está com dificuldades de mater resultados consistentes?</p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Ebook