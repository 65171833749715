import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Ebook from './pages/Ebook';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ebook" element={<Ebook />} />
      </Routes>
    </Router>
  );
}

export default App;

