import React, { useState } from 'react'
import logo from '../assets/logoWM.png'
import { Link } from 'react-scroll'
import { FaYoutube, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

const Navbar = () => {

    const [ navigation, setNavigation ] = useState(false)


    const links = [
        {
            id:1,
            link: 'home'
        },
        {
            id: 2,
            link: 'sobre wm'
        },{
            id: 3,
            link: 'produtos'
        },{
            id: 4,
            link: 'depoimentos'
        },{
            id: 5,
            link: 'contato'
        },
    ]

  return (
    <div className='w-screen fixed flex bg-darkColor justify-center text-white z-40'>
        <div className='lg:w-[80%] px-3 h-24 w-screen flex items-center justify-between'>
            <div>
                <img src={logo} alt='/' className='h-16 md:h-16 lg:h-20' />
            </div>
            <div>
                <ul className='hidden md:flex'>
                    {links.map (({ id, link }) => (
                        <li key={id} className='capitalize cursor-pointer p-4 hover:text-lightColor'>
                            <Link to={link} smooth duration={500}>
                                {link}
                            </Link>
                        </li>
                    ))}
                </ul>

                {/* Hamburger Menu */}

                <div className='md:hidden'>
                    <div onClick={() => setNavigation(true)} className='cursor-pointer'>
                        <AiOutlineMenu size={30} />
                    </div>
                </div>

                {/* Mobile Menu */}

                <div className={navigation ? 'md:hidden fixed left-0 top-0 w-full h-full bg-gray-900/70 backdrop-blur' : ''}>
                    <div className={navigation ? 'fixed left-0 top-0 w-4/5 h-full bg-gray-900 text-white p-10 ease-in duration-300' : 'fixed top-0 left-[-100%] h-full p-10 duration-300 ease-in'}>
                        <div className='flex'>
                            <div className='flex w-full items-center justify-between'>
                                <img src={logo} alt='/' className='h-16' />
                            </div>
                            <div onClick={() => setNavigation(false)} className='flex p-3 cursor-pointer'>
                                <AiOutlineClose size={30} />
                            </div>
                        </div>
                        <ul className='capitalize pt-5'>
                            {links.map(({ id, link}) => (
                                <li key={id} className='py-4 text-2xl tracking-wider'>
                                    <Link onClick={() => setNavigation(false)} to={link} smooth duration={500} offset={-75} className='cursor-pointer hover:text-lightColor'>
                                        {link}
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        <div className='pt-10 grid grid-cols-2 mx-auto w-4/5 gap-10'>
                            <div className='flex items-center justify-center rounded-md shadow-md shadow-lightColor cursor-pointer'>
                                <FaFacebook size={35} />
                            </div>
                            <div className='flex items-center justify-center rounded-md shadow-md shadow-lightColor cursor-pointer'>
                                <FaYoutube size={35} />
                            </div>
                            <div className='flex items-center justify-center rounded-md shadow-md shadow-lightColor cursor-pointer'>
                                <FaInstagram size={35} />
                            </div>
                            <div className='flex items-center justify-center rounded-md shadow-md shadow-lightColor cursor-pointer'>
                                <FaTiktok size={35} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar